.team-grid {
  justify-content: center;
  .team-item {
    @extend .col-sm-10;
    @extend .col-md-6;
    @extend .col-lg-5;
//     @extend .col-xl-4;
    margin-bottom: $grid-gutter-width;
/*
    &:nth-child(2n-1) {
      @extend .offset-lg-1;
    }
*/
    article {
      background-color: rgba($black,.04);
      height: 100%;
      figure {
        margin-bottom: 0;
      }
      div {
        padding: $grid-gutter-width*.5;
        font-size: .95rem;
        h3 {
          margin-bottom: 5px;
          margin-top: 0
        }
        h4 {
          font-style: italic;
          font-weight: bold;
          font-size: 1.1rem;
          margin-top: 0;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.job-listing {
  margin: $grid-gutter-width 0;
  padding: $grid-gutter-width;
  background-color: $white;
  border: 1px solid #eee;
  .job-title {
    margin: 0 0 1em;
    padding: 0 0 .3em;
    border-bottom: 1px solid #ddd;
  }
}

.page-header {
  h1 {
    margin-bottom: $grid-gutter-width*2;
  }
}
