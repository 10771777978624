img {
  @extend .img-fluid;
}
.wrap {
  padding-top: $grid-gutter-width*2;
  padding-bottom: $grid-gutter-width*2;
}
p,ol,ul,blockquote {
  margin-bottom: 1.5rem;
}
h1,h2,h3,h4,h5,h6 {
  margin-bottom: .5em;
}
h3 {
  margin-top: 1em;
}
