// Grid settings
$grid-gutter-width-base:  30px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      20px;

$main-sm-columns:         12;
$sidebar-sm-columns:      4;

$header-height:           200px;
$header-height-md:        $header-height;
$header-height-lg:        $header-height;
$header-height-xl:        $header-height;


$font-family-sans:      "Helvetica Neue", Arial, sans-serif;
$font-family-base:      $font-family-sans;

$black:                 #111;
$white:                 #fff;
$white-near:            #f6f6f6;
$green:                 #6c9305;

$brand-primary:         $green;
// $brand-secondary:       #333;

$body-bg:               $white-near;
$body-color:            $black;

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      $black;
$link-hover-decoration: underline;
$link-transition:       all .2s ease-in-out;
