header.banner {
  padding: $grid-gutter-height 0;
  background-color: $white;
//   border-bottom: 1px solid rgba($black,.1);
  box-shadow: 1px 0 2px rgba($black,.1);
  .brand {
    display: block;
    max-width: 280px;
    margin: 0 auto $grid-gutter-height;
    @include media-breakpoint-up(md) {
      max-width: 360px;
    }
  }
  .nav-primary {
    ul {
      justify-content: space-evenly;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
.hero {
  @extend .vh-height;
  background-position: center center;
  background-size: cover;
}
